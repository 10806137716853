import React, { Fragment, lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";

import Spinner from "./components/spinner/spinner";

const List = lazy(() => import("./containers/list/list"));
const Trip = lazy(() => import("./containers/trip/trip"));

const App = () => {
  return (
    <Fragment>
      <Switch>
        <Suspense fallback={<Spinner />}>
          <Route exact path="/" render={() => <List />} />
          <Route path="/trip" render={() => <Trip />} />
        </Suspense>
      </Switch>
    </Fragment>
  );
};

export default App;
